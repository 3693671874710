import React from 'react'
import PropTypes from 'prop-types'
import { VideoWrapper } from './Video.styles'

const Video = class extends React.Component {

  render() {
    return (
      <VideoWrapper className={`video video-${this.props.id} ${this.props.className || ''}`}>
        {this.props.videoURL && <>
          <iframe src={`${this.props.videoURL}?color=EF5B58&title=0&portrait=0&byline=0`} frameBorder="0" allowFullScreen={true} title={'video'}></iframe>
        </>
        }
      </VideoWrapper>
    );
  }
}

Video.propTypes = {
  className: PropTypes.string,
  videoURL: PropTypes.string.isRequired,
  id: PropTypes.string
}

export default Video
