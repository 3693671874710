import React from 'react'
import PropTypes from 'prop-types'
import { ChapterMetadataWrapper, CourseMetadata, MetadataIcon } from './ChapterMetadata.styles'
import DurationSVG from '../../img/chapter_duration.svg'
import CookSVG from '../../img/chapter_cook.svg'
import EbookSVG from '../../img/chapter_ebook.svg'
import DifficultySVG from '../../img/chapter_difficulty.svg'
import CategorySVG from '../../img/chapter_category.svg'
import ContentsSVG from '../../img/chapter_contents.svg'

const ChapterMetadata = class extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {

    return (
      <ChapterMetadataWrapper className={''}>
        <CourseMetadata>
          <MetadataIcon src={CategorySVG} />
          <span><b>Kategorie:</b> {this.props.category}</span>
        </CourseMetadata>
        <CourseMetadata>
          <MetadataIcon src={DurationSVG} />
          <span><b>Videozeit:</b> {this.props.duration}</span>
        </CourseMetadata>
        <CourseMetadata>
          <MetadataIcon src={ContentsSVG} />
          <span><b>Inhalt:</b> {this.props.chapters} Kapitel</span>
        </CourseMetadata>
        <CourseMetadata>
          <MetadataIcon src={EbookSVG} />
          <span><b>Workbook:</b> {this.props.ebookPages} Seiten</span>
        </CourseMetadata>
        <CourseMetadata>
          <MetadataIcon src={DifficultySVG} />
          <span><b>Schwierigkeit:</b> {this.props.difficulty}</span>
        </CourseMetadata>
        <CourseMetadata>
          <MetadataIcon src={CookSVG} />
          <span><b>Koch:</b> {this.props.cook.name}</span>
        </CourseMetadata>
      </ChapterMetadataWrapper>
    )
  };
}

ChapterMetadata.propTypes = {
  className: PropTypes.string,
  category: PropTypes.string,
  duration: PropTypes.string,
  chapters: PropTypes.number,
  ebookPages: PropTypes.number,
  difficulty: PropTypes.string,
  platforms: PropTypes.string,
  cook: PropTypes.string
}

export default ChapterMetadata
