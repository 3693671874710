import React from 'react'
import PropTypes from 'prop-types'
import { ChaptersWrapper, Chapter, ChapterTitle, DropdownButton, MoreButton, ChapterDescription, VideoStatusButton, ChapterHeaderRow, VideoTitle, PlaylistWrapper, CookWrapper, VideoDescription, DownloadButton, ChapterDuration, CookHeader } from './Chapters.styles'
import Video from '../../components/video/Video'
import { netlifyAuth } from '../../utils/auth'
import PlayIcon from '../../img/play.svg';
import PauseIcon from '../../img/pause.svg';
import { calculateTotalDuration, formateDuration, getImageUrl } from '../../utils/helpers'
import DropdownIcon from '../../img/dropdown.svg';
import ChapterMetadata from '../chapterMetadata/ChapterMetadata'

const Chapters = class extends React.Component {

  CHAPTER_SHOW_LIMIT = 7;

  constructor(props) {
    super(props)
    this.state = {
      active: -1,
      chapterShowLimit: this.CHAPTER_SHOW_LIMIT,
      videoURL: `https://player.vimeo.com/video/${this.props.trailerVimeoID}`, // default: trailer
      isVideoSticky: false,
      isPurchaseButtonSticky: false,
      isCoursePaid: false
    }
  }

  componentDidMount() {

    // Add scroll event listener
    this.toggleVideoSticky();

    // Get the latest paid courses information from API endpoint
    netlifyAuth.getIsCoursePaid(this.props.courseName).then(res => {
      this.setState({ isCoursePaid: res });
    });

  }

  /* Show details of one chapter entry, close others */
  toggleChapter = (index) => {

    // Hide current if clicked twice, else close others and open new
    if (this.state.active === index) {
      this.setState({ active: -1 });
    } else {
      this.setState({ active: index });
    }
  };

  toggleShowAll = () => {
    let currentLimit = this.state.chapterShowLimit;

    // Toggle between limit or unlimited = 1000
    this.setState({ chapterShowLimit: currentLimit === this.CHAPTER_SHOW_LIMIT ? 1000 : this.CHAPTER_SHOW_LIMIT });
  };

  playCourse = (vimeoID, chapter, index) => {

    // Load video player with new video url
    if (this.state.isCoursePaid || chapter.preview) {
      this.setState({ videoURL: `https://player.vimeo.com/video/${vimeoID}` });
    } else {
      this.toggleChapter(index);
    }
  };

  toggleVideoSticky = () => {

    const STICKY_VIDEO_THREASHOLD_START = 1100;

    document.onscroll = () => {
      const currentScrollY = window.scrollY;

      // Make video player and playlist sticky between two thresholds
      if (currentScrollY > STICKY_VIDEO_THREASHOLD_START) {
        this.setState({ isVideoSticky: true });
      } else {
        this.setState({ isVideoSticky: false });
      }
    };
  };

  getIsChapterPlaying = (chapterUrl) => {
    return this.state.videoURL.indexOf(chapterUrl) !== -1;
  };

  render() {

    if (!this.props.courseOnline) {
      return <></>;
    }

    return (
      <ChaptersWrapper className={'columns'}>
        <div className={'column is-12-mobile is-8-tablet is-8-desktop'} id={'video'}>
          <VideoTitle >{this.props.courseTitle}</VideoTitle>
          {this.state.isVideoSticky && <div className={'video-placeholder'} />}
          <Video videoURL={this.state.videoURL} className={`${this.state.isVideoSticky ? 'sticky-video' : ''}`} />
          <VideoDescription dangerouslySetInnerHTML={{ __html: this.props.courseDescription }} />
          <ChapterMetadata cook={this.props.cook} category={this.props.category} duration={calculateTotalDuration(this.props.chapters)} chapters={this.props.chapters.length} ebookPages={this.props.ebookPages} difficulty={this.props.difficulty} />
          {this.props.workbookDownload && this.state.isCoursePaid && <DownloadButton href={this.props.workbookDownload.publicURL} download={true} target={'_blank'}>Workbook herunterladen</DownloadButton>}
        </div>
        <div className={'column is-12-mobile is-4-tablet is-4-desktop'}>
          {this.props.cook && !this.state.isCoursePaid && <CookWrapper>
            <CookHeader>
              <img src={getImageUrl(this.props.cook.avatar)} />
              <div className={'text'}>
                {this.props.cook.name && <h3>{this.props.cook.name}</h3>}
                {this.props.cook.subtitle && <h4 className={'subtitle'}>{this.props.cook.subtitle}</h4>}
              </div>
            </CookHeader>
            <p>{this.props.cook.description}</p>
          </CookWrapper>
          }
          <span>Alle Kapitel:</span>
          <PlaylistWrapper className={`${this.props.className} margin-bottom`}>
            {
              this.props.chapters && this.props.chapters.map((chapter, index) => {
                if (index < this.state.chapterShowLimit) {
                  return <Chapter key={index}>
                    <ChapterHeaderRow>
                      {
                        (this.state.isCoursePaid || chapter.preview) && !this.getIsChapterPlaying(chapter.vimeoID) &&
                        <VideoStatusButton src={PlayIcon} onClick={() => this.playCourse(chapter.vimeoID, chapter)} alt={'Kapitel abspielen'} />
                      }
                      {
                        (this.state.isCoursePaid || chapter.preview) && this.getIsChapterPlaying(chapter.vimeoID) &&
                        <VideoStatusButton src={PauseIcon} onClick={() => this.playCourse(chapter.vimeoID, chapter)} alt={'Kapitel pausieren'} />
                      }
                      <ChapterTitle>
                        <span role='button' tabIndex={0} aria-hidden='true' onClick={() => this.playCourse(chapter.vimeoID, chapter, index)}> <b>{(index + 1).toString()}. {chapter.title}</b></span>
                        <ChapterDuration data-index={index} onClick={() => this.toggleChapter(index)}>{formateDuration(chapter.duration)}min.
                          <DropdownButton src={DropdownIcon} alt={'Kursdetailliste öffnen'} />
                        </ChapterDuration>
                      </ChapterTitle>
                    </ChapterHeaderRow>
                    {
                      this.state.active === index && <ChapterDescription>
                        <span dangerouslySetInnerHTML={{ __html: chapter.description }} />
                        {chapter.download && this.state.isCoursePaid && <DownloadButton href={chapter.download.publicURL} download={true} target={'_blank'}>Zutatenliste herunterladen</DownloadButton>}
                      </ChapterDescription>
                    }
                  </Chapter>;
                } else {
                  return null;
                }
              })
            }
            {
              this.props.chapters.length > this.CHAPTER_SHOW_LIMIT && <MoreButton onClick={this.toggleShowAll}>{
                this.state.chapterShowLimit === this.CHAPTER_SHOW_LIMIT ? ('Alle ' + this.props.chapters.length + ' Kapitel zeigen') : 'Weniger anzeigen'}
              </MoreButton>
            }
          </PlaylistWrapper>
        </div>
      </ChaptersWrapper>
    )
  };
}

Chapters.propTypes = {
  className: PropTypes.string,
  chapters: PropTypes.arrayOf(PropTypes.shape({
    vimeoID: PropTypes.string,
    title: PropTypes.string,
    preview: PropTypes.bool,
    duration: PropTypes.number,
    description: PropTypes.string
  })),
  courseName: PropTypes.string,
  courseTitle: PropTypes.string,
  courseDesciption: PropTypes.string,
  courseID: PropTypes.string,
  coursePrice: PropTypes.number,
  trailerVimeoID: PropTypes.string,
  pageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  courseOnline: PropTypes.bool,
  ebookPages: PropTypes.string,
  difficulty: PropTypes.string,
  platforms: PropTypes.string,
  category: PropTypes.string,
  workbookDownload: PropTypes.string
}

export default Chapters
