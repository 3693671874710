import styled from "styled-components";
import { breakpoints, margins } from "../../styles/styles";

export const ChapterMetadataWrapper = styled.div`
    display: grid;
    margin-bottom: ${margins.M};

    @media(${breakpoints.M}) {
        grid-template-columns: repeat(2, 1fr);
    }
`;

export const CourseMetadata = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${margins.S};
`;

export const MetadataIcon = styled.img`
    width: 20px;
    height: 20px;
    margin-right: ${margins.S};
`;
