import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../modules/layout/Layout'
import Stage from '../modules/stage/Stage'
import Testimonials from '../modules/testimonials/Testimonials'
import Voucher from '../modules/voucher/Voucher'
import BackgroundImg from '../img/background.svg'
import Payments from '../modules/payments/Payments'
import USP from '../modules/USP/USP'
import Chapters from '../modules/chapters/Chapters'
import SimilarCourses from '../modules/similar-courses/SimilarCourses'
import Impressions from '../modules/impressions/Impressions'
import { getLocalePrice } from '../utils/helpers'
import Benefits from '../modules/Benefits/Benefits'
import Separator from '../modules/separator/Separator'
import Newsletter from '../modules/newsletter/Newsletter'

export const CourseTemplate = ({
  description,
  title,
  frontendTitle,
  chapters,
  testimonials,
  id,
  pageId,
  price,
  cook,
  trailerVimeoID,
  image,
  impressions,
  stageVideoSmall,
  stageVideoLarge,
  courseOnline,
  category,
  difficulty,
  ebookPages,
  stageDescription,
  benefits,
  benefitsTitle,
  USPTitle,
  USPBoxHeadline,
  USPBoxBullets,
  USPImage,
  impressionsTitle,
  workbookDownload
}) => {

  return (
    <div data-id={id}>
      <div className={'wrapper'} style={{ backgroundImage: `url(${BackgroundImg})`, backgroundRepeat: 'repeat-y', backgroundSize: 'contain' }}>
        <Stage title={frontendTitle} hasPurchaseButton={true} image={image} purchaseText={`für ${getLocalePrice(price, true)}`} coursePrice={price} courseName={title} courseID={id} subtitle={`mit ${cook.name}<br/><br/>${stageDescription}`} stageVideoSmall={stageVideoSmall} stageVideoLarge={stageVideoLarge} pageId={pageId} courseOnline={courseOnline} isCoursePage={true} />
        <section className='content'>
          <Chapters chapters={chapters} coursePrice={price} courseTitle={frontendTitle} courseName={title} courseID={id} courseDescription={description} trailerVimeoID={trailerVimeoID} pageId={pageId} courseOnline={courseOnline} cook={cook} difficulty={difficulty} ebookPages={ebookPages} category={category} workbookDownload={workbookDownload} />
          <Impressions title={impressionsTitle} images={impressions} courseOnline={courseOnline} />
          <Separator />
          <USP USPTitle={USPTitle} USPBoxHeadline={USPBoxHeadline} USPBoxBullets={USPBoxBullets} USPImage={USPImage} />
          <Benefits benefits={benefits} benefitsTitle={benefitsTitle} />
          <Separator />
          <Testimonials testimonials={testimonials} />
          <Separator />
          <Payments />
          <Separator />
          <SimilarCourses courseName={title} currentCourseID={id} />
          <Voucher />
          <Newsletter />
        </section>
      </div>
    </div >
  )
}

CourseTemplate.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  helmet: PropTypes.object,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  chapters: PropTypes.arrayOf(PropTypes.object),
  testimonials: PropTypes.arrayOf(PropTypes.object),
  price: PropTypes.number,
  cook: PropTypes.object,
  impressions: PropTypes.arrayOf(PropTypes.object),
  stageVideoSmall: PropTypes.string,
  stageVideoLarge: PropTypes.string,
  courseOnline: PropTypes.bool,
  trailerVimeoID: PropTypes.string
}

const Course = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout
      seo_title={post.frontmatter.seo_title}
      seo_description={post.frontmatter.seo_description}
      seo_image={post.frontmatter.image}>
      <CourseTemplate
        description={post.frontmatter.description}
        title={post.frontmatter.title}
        frontendTitle={post.frontmatter.frontend_title}
        cook={post.frontmatter.cook}
        price={post.frontmatter.price}
        id={post.frontmatter.id}
        pageId={post.frontmatter.pageId}
        chapters={post.frontmatter.chapters}
        testimonials={post.frontmatter.testimonials}
        slug={post.frontmatter.slug}
        trailerVimeoID={post.frontmatter.trailerVimeoID}
        image={post.frontmatter.image}
        impressions={post.frontmatter.impressions}
        stageVideoSmall={post.frontmatter.video_small}
        stageVideoLarge={post.frontmatter.video_large}
        courseOnline={post.frontmatter.courseOnline}
        platforms={post.frontmatter.platforms}
        category={post.frontmatter.category}
        ebookPages={post.frontmatter.ebookPages}
        workbookDownload={post.frontmatter.workbookDownload}
        difficulty={post.frontmatter.difficulty}
        stageDescription={post.frontmatter.stageDescription}
        benefits={post.frontmatter.benefits}
        benefitsTitle={post.frontmatter.benefitsTitle}
        USPTitle={post.frontmatter.USPTitle}
        USPBoxHeadline={post.frontmatter.USPBoxHeadline}
        USPBoxBullets={post.frontmatter.USPBoxBullets}
        USPImage={post.frontmatter.USPImage}
        impressionsTitle={post.frontmatter.impressionsTitle}
      />
    </Layout>
  )
}

Course.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default Course

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        frontend_title
        id
        pageId
        description
        stageDescription
        seo_title
        seo_description
        price
        category
        ebookPages
        workbookDownload {
          publicURL
        }
        difficulty
        platforms
        cook {
          name
          subtitle
          description
          avatar {
            childImageSharp {
              fluid(maxWidth: 800) {
                src
              }
            }
          }
        }
        benefitsTitle
        benefits {
          title
          text
          image {
            childImageSharp {
              fluid(maxWidth: 800) {
                src
              }
            }
          }
        }
        USPTitle
        USPBoxHeadline
        USPBoxBullets {
          text
        }
        USPImage {
          publicURL
        }
        courseOnline
        image {
          childImageSharp {
            fluid(maxWidth: 1800) {
              src
            }
          }
        }
        video_small
        video_large
        chapters {
          title
          description
          vimeoID
          preview
          duration
          download {
            publicURL
          }
        }
        trailerVimeoID
        testimonials {
          title
          text
          author
          image {
            childImageSharp {
              fluid(maxWidth: 1600) {
                src
              }
            }
          }
        }
        impressionsTitle
        impressions {
          image {
            childImageSharp {
              fluid(maxWidth: 1200) {
                src
              }
            }
          }
        }
      }
    }
  }
`
