import React from 'react'
import PropTypes from 'prop-types'
import { SimilarCoursesWrapper } from './SimilarCourses.styles'
import Title from '../../components/title/Title'
import { StaticQuery } from 'gatsby'
import { graphql } from 'gatsby'
import Slider from '../slider/Slider'

class SimilarCourses extends React.Component {

  render() {

    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark;

    return (
      <SimilarCoursesWrapper className={`similar-courses`}>
        <Title title={'Weitere Kurse </br>entdecken'} centered={true} />
        <Slider items={posts.filter(item => { return item.node.frontmatter.id !== this.props.courseName.currentCourseID })} itemType={'CourseTile'} />
      </SimilarCoursesWrapper >
    )
  }
}

SimilarCourses.propTypes = {
  className: PropTypes.string,
  currentCourseID: PropTypes.string
}

export default (courseName, currentCourseID) => (
  <StaticQuery
    query={graphql`
      query SimilarCoursesQuery {
        allMarkdownRemark(
          filter: { 
            frontmatter: { 
              templateKey: { eq: "course-page" } 
              courseOnline: { eq: true }
            } 
          }
          sort: { fields: [frontmatter___courseOnline], order: DESC }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                id
                frontend_title
                templateKey
                price
                description
                courseOnline
                cook {
                  name
                }
                image {
                  childImageSharp {
                    fluid(maxWidth: 600) {
                      src
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <SimilarCourses data={data} courseName={courseName} currentCourseID={currentCourseID} count={count} />}
  />
)
