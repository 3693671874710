import React from 'react'
import PropTypes from 'prop-types'
import { ImpressionsWrapper, ImpressionImage } from './Impressions.styles'
import Title from '../../components/title/Title'
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import { getImageUrl } from '../../utils/helpers';


const Impressions = class extends React.Component {

  render() {

    if (!this.props.courseOnline) {
      return <></>;
    }

    return (
      <ImpressionsWrapper className={`${this.props.className} columns margin-bottom`}>
        <Title title={this.props.title} centered={true} />
        <AwesomeSlider className={'slider'}>
          {this.props.images && this.props.images.map((image, index) => {
            return <ImpressionImage data-src={getImageUrl(image.image)} key={index} alt={'Impressionen des Kurses (Gallerie)'} />;
          })}
        </AwesomeSlider>
      </ImpressionsWrapper>
    )
  }
}

Impressions.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.object)
}

export default Impressions
