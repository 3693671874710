import styled from "styled-components";
import { breakpoints, colors, margins, paddings } from "../../styles/styles";

export const ChaptersWrapper = styled.div`

    @keyframes sticky-video-visibility-animation {
        from {opacity: 0;}
        to {opacity: 1;}
    }

    margin-bottom: ${margins.XL} !important;
 
    .video {

        margin-right: ${margins.L};

        &.sticky-video {

            animation-name: sticky-video-visibility-animation;
            animation-duration: 0.75s;
            animation-fill-mode: forwards;

            @media(max-width: 450px) {
                position: fixed;
                top: 64px;
                left: ${margins.M};
                right: 0px;
                z-index: 8;       
            }
        }
    }

    .video-placeholder {
        padding-bottom:56.25%;
        position:relative;
        height:0;
    }
`;

export const PlaylistWrapper = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    margin-top: ${margins.M};
`;

export const Chapter = styled.div`
    padding: ${paddings.M};
    border-bottom: 1px solid #E7E7E7;

    &:last-of-type {
        border: none;
    }
`;

export const VideoPreviewWrapper = styled.div`

    filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1));
    overflow: hidden;

`;

export const VideoPreviewImage = styled.img`
    height: 300px;
    width: 500px;
    object-fit: cover;
`;

export const ChapterHeaderRow = styled.div`
    display: flex;
    align-content: center;
`;

export const ChapterTitle = styled.div`
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    flex-grow: 2;

    span {
        font-size: 16px !important;
        line-height: 24px !important;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        cursor: pointer;
    }
`;

export const ChapterDuration = styled.span`
    min-width: 110px;
`;

export const VideoTitle = styled.p`
    font-weight: bold;
    font-size: 24px !important;
    line-height: 24px !important;
    margin-top: ${margins.L};

    @media(${breakpoints.M}) {
        margin: 0px;
        margin-bottom: 18px !important;
        font-size: 36px !important;
    }

    @media(${breakpoints.L}) {
        font-size: 36px !important;
        margin-bottom: 28px !important;
    }
`;

export const VideoDescription = styled.p`
    margin-top: ${margins.L};
    max-width: 90%;
`;

export const ChapterDescription = styled.span`
    font-size: 16px !important;
    line-height: 24px !important;
    margin-top: ${margins.M};
    display: flex;
    flex-direction: column;
`;

export const DropdownButton = styled.img`
    margin-left: ${margins.L};
    cursor: pointer;
`;

export const MoreButton = styled.span`
    color: ${colors.primary} !important;
    text-decoration: underline;
    font-size: 16px !important;
    padding: ${paddings.M};
    display: flex;
    justify-content: center;
    cursor: pointer !important;
`;

export const VideoStatusButton = styled.img`
    float: left;
    padding-right: ${paddings.M};
    cursor: pointer;
`;

export const PurchaseWrapper = styled.div`

    display: flex;
    justify-content: center;
    margin: auto;
`;

export const DownloadButton = styled.a`
    color: ${colors.primary} !important;
    text-decoration: underline;
    padding-top: ${paddings.S};
`;

export const CookWrapper = styled.div`

    display: flex;
    margin-bottom: calc(2*${margins.L});
    margin-top: calc(2*${margins.L});
    flex-flow: column;
    
    img {
        border-radius: 100%;
        min-width: 103px;
        min-height: 103px;
        max-width: 103px;
        max-height: 103px;
        object-fit: cover;
        margin-bottom: ${margins.M};
        margin-right: ${margins.M};

        @media(${breakpoints.M}) {
            min-width: 173px;
            min-height: 173px;
            max-width: 173px;
            max-height: 173px;
        }
    }
`;

export const CookHeader = styled.div`
    display: flex;
    align-items: center;
`;