import styled from "styled-components";
import { breakpoints, colors, margins } from "../../styles/styles";

export const ImpressionsWrapper = styled.div`
    display: flex;
    margin-top: ${margins.XL};
    flex-direction: column;
    margin: auto;

    @media(${breakpoints.M}) {
        width: 50%;
        min-width: 628px;
    }

    .slider {
        --content-background-color: transparent;
        --loader-bar-color: ${colors.buttonPrimary};
        --control-bullet-color: #DCDCDC;
        --control-bullet-active-color: ${colors.buttonPrimary};
        --organic-arrow-color: white;

        .awssld__content {
            filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1));
            border-radius: 8px;
        }

        .awssld__bullets button {
            width: 10px;
            height: 10px;
            transform: unset;
        }
    }
`;

export const ImpressionImage = styled.div`

    height: 200px;
    width: 100%;
    object-fit: cover;

    @media(${breakpoints.L}) {
        height: 300px;
    }
`;