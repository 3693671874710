import styled from "styled-components";

export const VideoWrapper = styled.div`
    filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1));
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;

    .centered-in-video {
        position: absolute;
    }

    iframe {
        border-radius: 8px;
        background-color: black;
        left:0;
        top:0;
        height:100%;
        width:100%;
        position:absolute; 
    }
`;